<template>
    <div class="warp"
         v-loading="Loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="main">
        <div style="position: absolute;top: 47px;left: 263px;color: #f3ac0d;"><span>{{ info.plug_config_info.seasonTime }}</span></div>
        <div class="username"
             style="display:inline-block;float:right;color:rgb(189 247 12);margin-top: 44px;margin-right: 34px;">
          <span>当前角色：</span><span>{{ info.charac_name }}</span><a class="logout"
             href="javascript:;"
              @click="LoginDisplay = true">《登录|切换》</a>
        </div>
        <div style="margin-top:62px;">
          <el-row :gutter="20" style="height:322px;">
            <el-col :span="18">
              <ul class="stagelis" :class="ztlis">
                <li class="lilis" v-for="(item, index) in info.plug_config_info.plug_stage_items" :key="index">
                  <el-tooltip class="item" effect="dark" content="" placement="bottom">
                        <div slot="content">
                            <div style="color: #babd9a;width: 113px;">
                                <p style="text-align:center;margin-bottom:6px;">{{ item.name }}</p>
                                <p style="margin-bottom:6px;" v-for="va, indx in item.code"  :key="indx">{{ va.name }}x{{ va.num }}</p>
                            </div>
                        </div>
                        <div>
                          <div>
                            <img v-if="item.type == 0" :src="item.ioc" style="width: 28px;position: relative;left: 46px;top: 20px;;height: 28px;border:1px solid #9b55a1;"> 
                            <img v-if="item.type == 1" :src="item.ioc" style="width: 28px;position: relative;left: 46px;top: 20px;;height: 28px;border:1px solid #fff61d;"> 
                            <div v-if="item.state == 1" class="zzhao"></div>
                          </div>
                        </div>    
                    </el-tooltip> 
                </li> 
              </ul>
            </el-col>
            <el-col :span="6">
              <div style="position: relative; top: 84px;left: 25px;">
                <el-tooltip class="item" effect="dark" content="" placement="left">
                        <div slot="content">
                            <div style="color: #babd9a;width: 113px;">
                                <p style="text-align:center;margin-bottom:6px;">{{ info.plug_config_info.enditem.name }}</p>
                                <p style="margin-bottom:6px;" v-for="va, indx in info.plug_config_info.enditem.zz_code"  :key="indx">{{ va.name }}x{{ va.num }}</p>
                            </div>
                        </div>
                        <div>
                          <div>
                            <img  :src="info.plug_config_info.enditem.ioc" style="width: 28px;position: relative;left: 46px;top: 20px;;height: 28px;"> 
                            <div v-if="info.plug_config_info.enditem.state == 2" class="zzhao"></div>
                          </div> 
                        </div>    
                </el-tooltip> 
                <div v-if="info.plug_config_info.enditem.state == 0" class="lqann_jz"></div> <!-- 禁止领取 -->
                <div v-if="info.plug_config_info.enditem.state == 2" class="lqann_jz1"></div> <!-- 禁止领取 -->
                <div v-if="info.plug_config_info.enditem.state == 1" class="lqann" @click="Receive"></div> <!-- 可领取 -->
              </div>
              <!-- <div style="margin-top:186px;">
                <div  class="btn">重 置</div>
              </div> -->
              <!-- <div >
                <div style="text-align: center;position: absolute;right: 52px;top: 241px;color:#e70000;">
                  <p>本轮数据重置剩</p>
                  <p>14:00:00</p>
                </div>
              </div> -->
            </el-col>
            <el-col :span="24">
              <div style="position: absolute;left: 230px; width: 61px;display: inline-block;top: 362px; text-align: center;font-size: 18px;color: #e1a3f4;font-style: oblique;font-weight: 700;">
                <span>{{ info.plug_config_info.user.opportunity_num }}</span>
              </div>
              <div class="qdann" @click="StartLucky"></div>
              <!-- <div class="qdann_jz"></div> -->
              <!-- 能量累计 -->
              <div v-if="info.plug_config_info.user.equalizer_num == 0" class="nllji0"></div>
              <div v-if="info.plug_config_info.user.equalizer_num == 1" class="nllji1"></div>
              <div v-if="info.plug_config_info.user.equalizer_num == 2" class="nllji2"></div>
              <div v-if="info.plug_config_info.user.equalizer_num == 3" class="nllji3"></div>
              <div v-if="info.plug_config_info.user.equalizer_num == 4" class="nllji4"></div>
              <!-- 购买按钮 -->
              <div class="paybuy" @click="BuyDisplay = true"></div>
            </el-col>
          </el-row>
          
        </div>
      </div>
      <div class="titlehover">……</div>
      <div class="alert">
        <!-- 弹出框 -->
        <div class="alert_bg" v-if="MessageDisplay">
          <div class="alert_main">
            <div class="alert_title"><span>提示</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <span>{{ MessageMsg }}</span>
                <div class="btn"
                     @click="CloseAlert">关闭</div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <!-- 弹出框end -->
        <!-- 登陆框 -->
        <div class="alert_bg" v-if="LoginDisplay">
          <div class="alert_main"
               style="margin-top:86px;width:350px;margin-left:155px">
            <div class="alert_title"><span>绑定|切换</span></div>
            <div class="alert_con">
              <div class="alert_com">
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏账号：</span><input type="text"
                         v-model="login_info.username"
                         class="service__input"></p>
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">游戏密码：</span><input type="password"
                         v-model="login_info.password"
                         class="service__input"></p>
                <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 60px;">绑定角色：</span>
                  <select v-model="login_info.role"
                          style="width:154px;">
                    <option v-for="(itmec, i) in role_data"
                            :key="i"
                            :value="itmec.charac_no">
                      {{ itmec.charac_name }}</option>
                  </select>
                  <span style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;"
                        @click="getRole">获取</span>
                </p>
                <div>
                  <span style="padding:0px 0px;color:#f90000;"><span style="display:inline-block;padding:0px 0px;">{{ LoginError }}</span></span>
                </div>
                <div style="text-align:center;padding:10px 0px">
                  <div class="btn"
                       @click="login"
                       style="margin:0px 0px;display:inline-block;">绑定</div>
                  <div class="btn"
                       @click="LoginDisplay = false"
                       style="margin:0px 0px;display:inline-block;">取消</div>
                </div>
              </div>
            </div>
            <div class="alert_bottom"><span></span></div>
          </div>
        </div>
        <!-- 购买资格 -->
        <div class="alert_bg pop-buy" v-if="BuyDisplay">
            <div class="alert_main">
            <i style="position: relative;left: 143px;top: 113px;z-index: 20;font-size: 18px;color: #bc9b9b;"  @click="BuyDisplay = false">x</i>
            <div class="alert_title" style="margin-top:92px;"><span>启动资格</span></div>
            <div class="alert_con">
                <div class="alert_com">
                  <div style="width: 93%;display: inline-block;float: left;text-align: left;padding: 10px 10px;color: #ff7878;">
                      <div style="border: 1px dashed #444; position: relative">
                        <div>
                          <p>每购买一次启动机会，需消耗{{ info.plug_config_info.user.type_name }}，请选择需要购买次数</p>
                          <input type="text" v-model="buy_num" max="100" placeholder="输入需要购买次数" />
                        </div>
                      </div>   
                  </div>
                  <div style="display: inline-block;float: right;position: absolute;top: 40px;right: 14px;">
                      <a class="btn cera"
                      href="javascript:;" style="margin-top: 22px; margin-right: 5px;" @click="Buy">&nbsp;购买</a>
                  </div>
                </div>
            </div>
            <div class="alert_bottom"><span></span></div>
            </div>
        </div>
      </div>
    </div>
  
  </template>
  <script>
  import Vue from 'vue'
  export default {
    name: "Monthsign",
    data () {
      return {
        MessageDisplay: false, //弹出框显示
        LoginDisplay: false, //登陆弹出框
        LoginError: '',
        MessageMsg: '', //弹出框消息
        Loading: false,
        BuyDisplay:false,
        info: {
          m_id: 0, //账号uid
          charac_no: 0, //角色ID
          charac_name: '', //角色名称
          plug_config_info:{
            plug_stage_items:[],
            enditem:{
              name:'',
              ioc:'',
              zz_code:[],
              state:0,
            },
            user:{
              equalizer_num:0,
              opportunity_num:0,
              type_name:'',
            },
            seasonTime:'',
          },
        },
        login_info: {
          username: '',
          password: '',
          role: '',
        },
        role_data: [],
        ztlis:'', //编号
        ztlis_num:0, //次数
        buy_num:1,
        results:'',
       
      };
    },
    created () {
     this.getInfo()
    },
    methods: {
      //获取基本配置信息
      getInfo () {
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/stage/getStageInfo',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.info = response.data.info;
          } else {
            this.PopMessage(true, response.data.msg)
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      //登陆
      login () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/PlugLogin',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
            role: this.login_info.role,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.LoginDisplay = false
            this.getInfo()
          } else {
            this.LoginError = response.data.msg
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      //获取角色
      getRole () {
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/getRole',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            username: this.login_info.username,
            password: this.login_info.password,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          if (response.data.code == 200) {
            this.role_data = response.data.info
          } else {
            this.LoginError = response.data.msg
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      
      //弹出框状态
      PopMessage (type, msg) {
        this.MessageDisplay = false
        this.MessageMsg = msg
        if (type == true) {
          this.MessageDisplay = true
        } else {
          this.getInfo()
        }
      },
      //关闭窗口
      CloseAlert () {
        this.PopMessage(false, '')
      }, 
      //启动提交
      StartLucky(){
        var that = this;
        if (that.Loading == true) {
          return false
        }
        that.Loading = true
        that.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/stage/StageStartLucky',
          // 传递参数
          data: {
            key: that.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          that.Loading = false
          that.BuyDisplay = false
          if (response.data.code == 200) {
            that.results =  response.data.msg
            that.LuckyDrawZp();
            setTimeout(function () {
              that.PopMessage(true, that.results);
              that.results = ""
            },700);
          } else {
            that.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          that.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      LuckyDrawZp(){
        var that = this;
        setTimeout(function ()  {
            if(that.ztlis_num < 13){
              that.ztlis = "ztlis"+that.ztlis_num;
              that.ztlis_num += 1;
              console.log(that.ztlis_num)
              that.LuckyDrawZp();
            }else{
              that.ztlis = "";
              that.ztlis_num = 0;
            }
        },50);
      },
      Buy(){
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/stage/BuyStage',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
            num:this.buy_num,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          this.BuyDisplay = false
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
      Receive(){
        if (this.Loading == true) {
          return false
        }
        this.Loading = true
        this.axios({
          // 默认请求方式为get
          method: 'post',
          url: Vue.prototype.config.webplugurl + '/stage/StageReceive',
          // 传递参数
          data: {
            key: this.$route.query.key,
            keyid:this.$route.query.id,
          },
          responseType: 'json'
        }).then(response => {
          this.Loading = false
          this.BuyDisplay = false
          if (response.data.code == 200) {
            this.PopMessage(true, response.data.msg);
          } else {
            this.PopMessage(true, response.data.msg);
          }
        }).catch(error => {
          this.Loading = false
          // 请求失败，
          console.log(error)
        });
      },
    },
  };
  </script>
  
  
  <style scoped>
  @import "../../../public/static/plug/stage/static/css/game.css";
  </style>
  

  

<style>
#root,
body,
html {
    min-width: 0px;
}
</style>
<style lang="scss" scoped>
 /deep/ .el-row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
</style>
  
  